import { api } from '@teamflow/client-api'

export enum PaymentTerm {
    Monthly = 'monthly',
    Yearly = 'yearly',
}

export enum PaymentPlan {
    Seed = 'seed',
    Business = 'business',
}

const cachedPriceIds: { [key: string]: string } = {}

export const initialize = async () => {
    const { data: priceIds } = await api.billing.prices.list()
    Object.assign(cachedPriceIds, priceIds)
}

export const getPriceId = async (plan: PaymentPlan, term: PaymentTerm) => {
    if (Object.keys(cachedPriceIds).length <= 0) {
        // if not cached then try to retrieve
        await initialize()
    }

    switch (plan) {
        case PaymentPlan.Seed:
            return term === PaymentTerm.Monthly
                ? cachedPriceIds.SeedMonthly
                : cachedPriceIds.SeedYearly

        case PaymentPlan.Business:
            return term === PaymentTerm.Monthly
                ? cachedPriceIds.BusinessMonthly
                : cachedPriceIds.BusinessYearly
    }
}
