import { loadStripe } from '@stripe/stripe-js'

import result from '@teamflow/lib/src/async/result'
import retry from '@teamflow/lib/src/async/retry'

export const loadStripeSafe = async () => {
    const [err, stripe] = await result(
        retry(
            () => loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY as string),
            3,
            0,
            1
        )
    )

    if (err || !stripe) {
        return null
    }

    return stripe
}
