import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Column, Columns, Divider, Input, Stack } from '@teamflow/design'
import allTKeys from '@teamflow/translations'

import HuddleCardElement from './HuddleCardElement'

const translationPage = 'common'
const tKeys = allTKeys.common.billingForm

export interface IAddress {
    line1: string
    city: string
    state: string
    postal_code: string
}

type Props = {
    onAddressChanged: (address: IAddress) => void
    origin?: 'team' | null
    dark?: boolean
}

export default function BillingForm({ onAddressChanged, origin, dark }: Props) {
    const { t } = useTranslation(translationPage)

    const [address, setAddress] = useState({
        line1: '',
        city: '',
        state: '',
        postal_code: '',
    })

    const updateAddress = useCallback(
        (key: string, evt: React.ChangeEvent<HTMLInputElement>) => {
            const change = {} as { [key: string]: string }
            const val =
                key === 'postal_code'
                    ? evt.currentTarget.value.substring(0, 12)
                    : evt.currentTarget.value

            change[key] = val

            const newAddress = {
                ...address,
                ...change,
            }

            setAddress(newAddress)

            onAddressChanged(newAddress)
        },
        [address, onAddressChanged]
    )

    const onUpdateLine1 = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) =>
            updateAddress('line1', evt),
        [updateAddress]
    )
    const onUpdateCity = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) =>
            updateAddress('city', evt),
        [updateAddress]
    )
    const onUpdateState = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) =>
            updateAddress('state', evt),
        [updateAddress]
    )
    const onUpdatePostalCode = useCallback(
        (evt: React.ChangeEvent<HTMLInputElement>) =>
            updateAddress('postal_code', evt),
        [updateAddress]
    )

    return (
        <Stack space="space16">
            <Input
                placeholder={t(tKeys.address)}
                value={address.line1}
                onChange={onUpdateLine1}
                data-testid="address-line1"
                dark={origin === 'team'}
            />

            <Columns space="space10">
                <Column flex={2}>
                    <Input
                        placeholder={t(tKeys.city)}
                        value={address.city}
                        onChange={onUpdateCity}
                        data-testid="address-city"
                        dark={origin === 'team'}
                    />
                </Column>
                <Column flex={1}>
                    <Input
                        placeholder={t(tKeys.state)}
                        value={address.state}
                        onChange={onUpdateState}
                        data-testid="address-state"
                        dark={origin === 'team'}
                    />
                </Column>
                <Column flex={1}>
                    <Input
                        placeholder={t(tKeys.zip)}
                        value={address.postal_code}
                        onChange={onUpdatePostalCode}
                        data-testid="address-zip"
                        dark={origin === 'team'}
                    />
                </Column>
            </Columns>

            <Box paddingTop="space16">
                <HuddleCardElement dark={dark} />
            </Box>

            <Divider
                color={dark ? 'neutral70' : 'neutral40'}
                marginTop="space12"
            />
        </Stack>
    )
}
