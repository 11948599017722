import { CardElement } from '@stripe/react-stripe-js'

export default function HuddleCardElement({ dark }: { dark?: boolean }) {
    return (
        <CardElement
            options={{
                hidePostalCode: true,
                style: {
                    base: {
                        fontSize: '16px',
                        // TODO: after adding color lib
                        // make #f3f3f3 hslaToHex(tokens.color.neutral20)
                        // make #111828 hslaToHex(tokens.color.foreground)
                        color: dark ? '#f3f3f3' : '#111828',
                        '::placeholder': {
                            // TODO:
                            // make #949cab tokens.color.neutral20 with alpha=0.85
                            // make #b0b9c6 tokens.color.foreground with alpha=0.85
                            color: dark ? '#949cab' : '#b0b9c6',
                        },
                    },
                    invalid: {
                        // TODO: make this hslaToHex(tokens.color.danger)
                        color: '#eb5656',
                    },
                },
            }}
        />
    )
}
