import { useState, useEffect } from 'react'

import { api } from '@teamflow/client-api'
import { LogManager } from '@teamflow/lib'
import result from '@teamflow/lib/src/async/result'
import { retryWithTimeout } from '@teamflow/lib/src/async/retry'
import type { Coupon } from '@teamflow/types/src/api/billing'

export default function useCouponCode(
    coupon?: string,
    productId?: string
): [Coupon | undefined, (val: number) => number, boolean] {
    const [couponData, setCouponData] = useState<Coupon>()
    const [hasDiscount, setHasDiscount] = useState<boolean>(false)

    const retrieveCouponInfo = async (couponId: string, productId: string) => {
        const [netError, netData] = await result(
            retryWithTimeout(() =>
                api.billing.coupons.get({ couponId, productId })
            )
        )

        if (netError || netData?.error) {
            LogManager.global.error(
                'Error in useCouponCode',
                (netError ?? netData?.error ?? { message: 'unknown' }).message
            )
            return
        }

        if (!netData?.data) {
            return
        }

        const { coupon } = netData.data

        setCouponData(coupon)
        setHasDiscount(!!coupon?.amountOff || !!coupon?.percentOff)
    }

    useEffect(() => {
        if (!coupon || !productId) {
            return
        }

        void retrieveCouponInfo(coupon, productId)
    }, [productId, coupon])

    const discountedPrice = (val: number) => {
        if (couponData) {
            const { percentOff, amountOff } = couponData
            if (percentOff) {
                return val - val * percentOff
            } else if (amountOff) {
                return Math.max(0, val - amountOff)
            }
        }

        return val
    }

    return [couponData, discountedPrice, hasDiscount]
}
