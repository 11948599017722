import { ChangeEvent, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Columns, Input, Skeleton, Stack, Text } from '@teamflow/design'
import allTKeys from '@teamflow/translations'
import { IProductResponse } from '@teamflow/types'

import { PaymentTerm } from '../../helpers/billing'
import { formatDiscount, formatPrice } from '../../helpers/format'
import useCouponCode from '../../hooks/useCouponCode'

const translationPage = 'signup'
const tKeys = allTKeys.signup.creditCard

interface Props {
    product: IProductResponse | undefined
    coupon: string | undefined
    seats: number
    term: PaymentTerm
    onSeatsChanged: (seats: number) => void
    onBlur?: () => void
}

export default function ProductCostCard({
    product,
    coupon,
    seats,
    term,
    onSeatsChanged,
    onBlur,
}: Props) {
    const { t } = useTranslation(translationPage)

    const [couponData, discountedPrice] = useCouponCode(coupon, product?.id)

    const handleSeatsChanged = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value
            const newVal = val ? parseInt(val) : 0
            if (isNaN(newVal) || newVal < 0) {
                onSeatsChanged(0)
            } else {
                onSeatsChanged(newVal)
            }
        },
        [onSeatsChanged]
    )

    const invalidSeats = seats <= 0

    const handleOnBlur = useCallback(() => {
        if (invalidSeats) {
            onSeatsChanged(1)
        }
        onBlur?.()
    }, [invalidSeats, onBlur, onSeatsChanged])

    if (!product) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center">
                <Skeleton
                    borderRadius="borderRadius8"
                    __cssOverrides={{ width: 234, height: 122 }}
                />
            </Box>
        )
    }

    return (
        <Box
            background="background"
            borderWidth="borderWidth1"
            borderRadius="borderRadius8"
            borderColor="neutral40"
            borderStyle="solid"
            __cssOverrides={{ maxWidth: 400 }}
        >
            {product.images[0] ? (
                <img src={product.images[0]} alt={product.name} />
            ) : null}
            <Box padding="space24">
                <Stack space="space12">
                    <Text weight="600">{product.name}</Text>

                    <Stack space="space8">
                        <Text>
                            <Trans
                                t={t}
                                i18nKey={tKeys.priceSelectedTermInTotal}
                                values={{
                                    price: formatPrice(
                                        discountedPrice(
                                            product.unit_amount ?? 0
                                        ) * seats
                                    ),
                                    selectedTerm: term,
                                }}
                            >
                                <Text color="neutral70" size="textSize12">
                                    text
                                </Text>
                            </Trans>
                        </Text>
                        {couponData?.percentOff &&
                            // NOTE: checking percentOff > 0.1 because coupons have been
                            // used to track conversions by creating very small coupon codes
                            // this is likely not used anymore and could possibly be
                            // removed
                            couponData?.percentOff > 0.1 && (
                                <Text color="neutral60" size="textSize12">
                                    <Trans
                                        t={t}
                                        i18nKey={
                                            tKeys.youSavedAmountWithCodeCoupon
                                        }
                                        values={{
                                            amount: formatDiscount(
                                                couponData?.percentOff
                                            ),
                                            coupon,
                                        }}
                                    >
                                        <b>text</b>
                                    </Trans>
                                </Text>
                            )}
                    </Stack>

                    <Columns space="space4" alignY="center">
                        <Text color="neutral70" size="textSize14">
                            Seats:
                        </Text>
                        <Input
                            type="text"
                            width="size64"
                            height="size24"
                            value={seats}
                            onChange={handleSeatsChanged}
                            onSubmit={handleOnBlur}
                            onBlur={handleOnBlur}
                        />
                        <Text color="neutral60" size="textSize12">
                            x{' '}
                            {formatPrice(
                                discountedPrice(product.unit_amount ?? 0)
                            )}
                        </Text>
                    </Columns>
                </Stack>
            </Box>
        </Box>
    )
}
